import { WebsiteConf } from '@config/website'
import { LazyScroll } from '@headless/ui'
import { Helmet } from 'react-helmet'

import CmsBanner from './CmsBanner'
import CmsProduct from './CmsProduct'
import { StyledHomePage } from './styled'

const HomePage = ({ children }: any) => {
  const { isCustomized } = WebsiteConf
  return isCustomized ? (
    <StyledHomePage>
      <Helmet>
        <meta
          name="title"
          content="Enamel Jewelry | Earrings, Necklaces, Bracelets | YESSA"
        />
        <meta
          name="keywords"
          content="Enamel Earrings, Enamel Necklaces, Enamel Bracelets, Enamel Brooches, Enamel Rings"
        />
        <meta
          name="description"
          content="Shop YESSA for unique and quality enamel earrings, enamel necklaces, enamel bracelets, enamel brooches, enamel rings. Free shipping and returns!"
        />
        <title>Enamel Jewelry | Earrings, Necklaces, Bracelets | YESSA</title>
      </Helmet>
      <CmsBanner />
      <div className="container">
        <LazyScroll>
          <CmsProduct />
        </LazyScroll>
        {children}
        {/* <LazyScroll>
          <CmsGallery />
        </LazyScroll> */}
      </div>
    </StyledHomePage>
  ) : (
    <>{children}</>
  )
}

export default HomePage
